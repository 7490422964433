import React, { useCallback } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { debounce } from 'lodash';
import ListItem from '@material-ui/core/ListItem';
import { getDuration, getPhoneNumber, getDateTime } from '@KazooSDK/utils';
import { KazooSDK } from '@KazooSDK';
import { toast } from '@Utils/toast';
import { useStylesMobileCallItem } from './styles';
import { PhoneCallStatus } from '../../../../Common/PhoneCallStatus';
import { useTranslation } from 'react-i18next';

const sleep = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const MobileCallItem = ({ row, device_id }) => {
  const classes = useStylesMobileCallItem();
  const { t } = useTranslation();

  const handleCall = useCallback(
    debounce(async () => {
      let to = KazooSDK.isIncomingCall(row) ? row.from : row.to;
      try {
        to = to.split('@')[0]; // getPhoneNumber(to);
      } catch (err) {
        toast({
          type: 'error',
          title: `Unable to call invalid number: ${to}`
        });
        return false;
      }
      toast({
        type: 'success',
        title: device_id
          ? t('callHistory.toast.ringingYourPhone.label')
          : t('callHistory.toast.ringingYourDevices.label')
      });

      // delay while notifying user
      await sleep(1000);

      KazooSDK.makeCall({ to, device_id })
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.warn(e);
        });
    }, 500),
    [row, device_id]
  );

  return (
    <ListItem className={classes.item} onClick={handleCall}>
      <div
        className={classNames([classes.wrapperContact], {
          [classes.red]: KazooSDK.isMissedCall(row)
        })}
      >
        <PhoneCallStatus
          interaction={row}
          direction={row.direction}
          hangup_cause={row.hangup_cause}
        />
        <div>
          {row.caller_id_name && <p>{getPhoneNumber(row.caller_id_name)}</p>}
          <p>{getPhoneNumber(row.caller_id_number)}</p>
          <br />
          <p>{moment.utc(getDateTime(row.timestamp).date).fromNow()}</p>
        </div>
      </div>
      <div className={classNames([classes.wrapperContact, classes.alignRight])}>
        <div>
          {row.callee_id_name ? (
            <p>{getPhoneNumber(row.callee_id_name)}</p>
          ) : (
            <p>{row.dialed_number}</p>
          )}
          <p>{getPhoneNumber(row.callee_id_number)}</p>
          <br />
          <p>{getDuration(row.duration_seconds)}</p>
        </div>
      </div>
    </ListItem>
  );
};
